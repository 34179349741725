<template>
    <div class="header">

<!--        <div class="pc_marquee" style="width: 100%;text-align: center">-->
<!--            <marquee class="marquee" style="width: 100%;margin: 2px 0">-->
<!--                {{lineMessage}}-->
<!--            </marquee>-->
<!--        </div>-->
        <div class="mobile-menu-left-icon micon" @click="showMobileTopMenu">
            <img src="../assets/images/icon/mobile/topmenu/menu_white.svg" alt="">
        </div>
        <div class="menu1">
            <div class="top1">
                <div class="logo" @click="toMain">
                    <img src="../assets/images/logo/ptn/logo01.gif" alt="">
                </div>
                <div class="linemessge">
                    <div style="width: 55%;display: flex;justify-content: flex-start;align-items: center" v-if="$store.state.isLogin">
                        <div class="minfo" @click="initUserInfo">
                            <img :src="$store.state.userInfo.rank.imageUrl" alt="" class="mimg">
                            <span class="mspan">{{$store.state.userInfo.nickname}}</span>
                        </div>
                        <div class="minfo" @click="initUserInfo">
                            <img src="../assets/images/icon/cenha/icon-top-money.png" alt="" class="mimg">
                            <span class="mspan">Cash: {{$store.state.userInfo.cash|comma}}</span>
                        </div>
                        <div class="minfo" @click="changePoint">
                            <img src="../assets/images/icon/cenha/icon-top-point.png" alt="" class="mimg">
                            <span class="mspan">Point: {{$store.state.userInfo.userPoint|comma}}</span>
                        </div>
                        <div class="minfo" @click="refreshCasinoCash">
                            <img src="../assets/images/icon/cenha/icon-top-casino.png" alt="" class="mimg">
                            <span class="mspan">Casino Chips: {{$store.state.userInfo.casinocash|comma}}</span>
                        </div>
                    </div>
                    <div style="width: 45%;display:flex;justify-content: flex-end;align-items: center">
<!--                        <router-link tag="div" class="minfobutton" to="" @click.native="changePoint"-->
<!--                                     style="width: 100px"  v-if="$store.state.isLogin">포인트전환-->
<!--                        </router-link>-->
                        <router-link tag="div" class="minfobutton"
                                     :to="{path: '/message', query: {t: new Date().getTime()}}">Message
                        </router-link>
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/culcheck', query: {t: new Date().getTime()}}">Attendance-->
<!--                        </router-link>-->
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/coupon', query: {t: new Date().getTime()}}">Coupon-->
<!--                        </router-link>-->
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/recmember', query: {t: new Date().getTime()}}">Recommender-->
<!--                        </router-link>-->
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/user_info', query: {t: new Date().getTime()}}">회원정보-->
<!--                        </router-link>-->
                        <router-link tag="div" class="minfobutton" to="" @click.native="logout" v-if="$store.state.isLogin">Sign out</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu2">
            <div class="top2">
                <router-link :to="{path: '/sports', query: {t: new Date().getTime()}}" tag="a" class="item"
                             @click.native="changeSportsDisplay(cross)">Sports
                </router-link>
                <div class="dote"></div>


<!--                <router-link :to="{path: '/casino', query: {type:1,t: new Date().getTime()}}" tag="a" class="item">-->
<!--                   Casino&Slot-->
<!--                </router-link>-->
              <router-link :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}" tag="a" class="item">
                Casino&Slot
              </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/jiligames', query: {t: new Date().getTime()}}" tag="a" class="item">JiliGames
                </router-link>
                <div class="dote"></div>

              <router-link :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}" tag="a" class="item">
                IN-PLAY
              </router-link>
              <div class="dote"></div>



                <!--                <router-link :to="{path: '/leisure/eospowerball5m', query: {t: new Date().getTime()}}" tag="a"-->
<!--                             class="item">-->
<!--                    미니게임-->
<!--                </router-link>-->
<!--                <div class="dote"></div>-->



<!--                <router-link :to="{path: '/leisure/bet365_superleague', query: {t: new Date().getTime()}}" tag="a"-->
<!--                             class="item">-->
<!--                    BET365-->
<!--                </router-link>-->
<!--                <div class="dote"></div>-->

                <!--<router-link :to="{path: '/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}" tag="a"
                             class="item">
                   Tgame365
                </router-link>
                <div class="dote"></div>-->

                <router-link :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}" tag="a" class="item">Betting History
                </router-link>
                <div class="dote"></div>

<!--                <router-link :to="{path: '/sports_result', query: {t: new Date().getTime()}}" tag="a" class="item">경기결과-->
<!--                </router-link>-->
<!--                <div class="dote"></div>-->

                <router-link :to="{path: '/customer_center', query: {t: new Date().getTime()}}" tag="a" class="item">
                    Customer Service
                </router-link>
                <div class="dote"></div>


                <router-link :to="{path: '/notice', query: {t: new Date().getTime()}}" tag="a" class="item">Notice
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/event', query: {t: new Date().getTime()}}" tag="a" class="item">Jackpot News
                </router-link>
                <div class="dote"></div>


                <router-link :to="{path: '/recharge', query: {t: new Date().getTime()}}" tag="a" class="item">
                    <img src="../assets/images/icon/common/main-topbar-icon04.png" alt="" style="height: 26px"><span style="margin-left: 3px">CASH IN</span>
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/exchange', query: {t: new Date().getTime()}}" tag="a" class="item">
                    <img src="../assets/images/icon/common/main-topbar-icon05.png" alt="" style="height: 26px"><span style="margin-left: 3px">CASH OUT</span>
                </router-link>


            </div>

        </div>

        <div class="mobile_linemessage">

<!--            <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;height: 36px;line-height: 36px;color: #fff9ff;font-size: 12px;background-color: #092f59">-->
<!--                <div style="flex: 1;text-align: center;" @click="initUserInfo">-->
<!--                    <img src="../assets/images/icon/cenha/icon-top-money.png" alt="" style="width: 18px;margin-right: 3px"> <span-->
<!--                        class="txt-cash">{{$store.state.userInfo.cash|comma}}</span>-->
<!--                </div>-->

<!--                <div style="flex: 1;text-align: center" @click="initUserInfo">-->
<!--                    <img src="../assets/images/icon/cenha/icon-top-point.png" alt="" style="width: 18px;margin-right: 3px"> <span-->
<!--                        class="txt-cash">{{$store.state.userInfo.userPoint|comma}}</span>-->
<!--                </div>-->
<!--                <div style="flex: 1;text-align: center" @click="refreshCasinoCash">-->
<!--                    <img src="../assets/images/icon/cenha/icon-top-casino.png" alt="" style="width: 18px;margin-right: 3px"> <span-->
<!--                        class="txt-cash">{{$store.state.userInfo.casinocash|comma}}</span>-->
<!--                </div>-->
<!--            </div>-->
            <div style="display: flex;align-items: center;justify-content: space-evenly;width: 100%;height: 36px;line-height: 36px;color: #fff9ff;font-size: 12px;;margin-top: 2px">
                <router-link to="/recharge" tag="button" class="cashin">
                    <i class="fa fa-plus-square"></i>  CASH IN
                </router-link>
                <router-link to="/exchange" tag="button" class="cashout">
                    <i class="fa fa-minus-square"></i> CASH OUT
                </router-link>
            </div>
        </div>
        <div class="mobile-menu-right-icon micon">
            <img src="../assets/images/icon/mobile/topmenu/signin_white.svg" class="" alt=""
                 v-if="!$store.state.isLogin"
                 @click="showLoginPanel()">
            <img src="../assets/images/icon/mobile/topmenu/user_white.svg" class="" alt="" v-if="$store.state.isLogin"
                 @click="rightBarHandle">
        </div>
        <!--모바일 메뉴-->
        <mobile-top-menu></mobile-top-menu>
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {changePoint, getLineMessage, getSiteStatus, logout} from "../network/userRequest";
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {getAccountBalance} from "../network/casinoHonorRequest";
    //import {getAccountBalance} from "../network/casinoXimax2Request";
    import {getJiliGamesBalance} from "../network/jiliGamesRequest";

    export default {
        name: "TopbarComp",
        components: {MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                lineMessage: '',
            }
        },
        methods: {
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', !this.$store.state.mobileRightMenuStatus)
                this.$store.state.mobileRightMenuStatus = !this.$store.state.mobileRightMenuStatus
            },
            showLoginPanel() {
                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
                //this.$bus.$emit('leftBarHandle', true)
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    //title: '로그아웃 하시겠습니까 ?',
                    title: 'Do you want to sign out?',
                    type: 'question',
                    confirmButtonText: 'Sign Out',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/main")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            },
            getLineMessage() {
                getLineMessage().then(res => {
                    if (res.data.success) {
                        let lines = res.data.data;
                        lines.map(line => {
                            this.lineMessage = this.lineMessage + '   ' + line.content
                        })
                    }

                })
            },
            refreshCasinoCash() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                setTimeout(() => {
                    getAccountBalance().then(res => {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    })
                }, 300)

            },
            initUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                this.$store.dispatch('actionUserInfo')
                setTimeout(() => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                }, 500)
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    logout()
                    this.$router.replace("/main")
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    this.$store.state.isShowLoginPanel = true;
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            if (this.$store.state.isLogin == false) {
                this.$router.replace("/main")
                this.$store.state.isShowLoginPanel = true;
            }

            this.$store.state.ismobile = this.browser().mobile
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            this.getLineMessage();
            if (this.$store.state.isLogin) {
                this.$store.state.uncheckedMessageCount = 0;
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
                setTimeout(() => {
                    getAccountBalance().then(res => {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    })
                }, 300)

                setTimeout(() => {
                    getJiliGamesBalance().then(res => {
                        this.$set(this.$store.state.userInfo, 'jilicash', res.data.data)
                    })
                }, 1000)
            } else {
                this.$store.commit(INIT_USERINFO);
            }

        },
    }
</script>

<style scoped>
    /* 해더 */
    .header {
        width: 100%;
        height: 160px;
        background-color: var(--headerBg);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        transition: width .2s ease-in-out, left .2s ease-in-out;
        z-index: 1010;
    }

    .header .menu1 {
        width: 100%;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .header .menu1 .top1 {
        width: 100%;
        height: 100%;
        padding: 5px 336px 5px 336px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }


    .header .menu1 .top1 .logo {
        position: relative;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #fff9ff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .menu1 .top1 .logo img {
        width: 100%;
        max-width: 150px;
    }

    .header .menu1 .top1 .linemessge {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .header .menu1 .top1 .linemessge .minfo {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 5px;
        cursor: pointer;
    }

    .minfo .mimg {
        height: 24px;
    }

    .minfo .mspan {
        font-size: 14px;
        line-height: 22px;
        padding-left: 3px;
        font-weight: bold;
    }

    .header .menu1 .top1 .linemessge .minfobutton {
        width: 90px;
        height: 24px;
        line-height: 24px;
        padding: 0 3px;
        border-radius: 3px;
        background-color: #b04e11;
        border: 1px solid #b04e11;
        margin: 0 3px;
        cursor: pointer;
        text-align: center;
    }


    .header .menu1 .top1 .linemessge .top1_links {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px
    }

    .header .menu1 .top1 .linemessge .top1_links button {
        padding: 5px 10px;
        margin: 5px 0 0 5px;
        background-color: var(--btn01Bg);
        color: var(--btn01Color);
        border-radius: 3px;
    }

    .header .menu1 .top1 .linemessge .top1_links button:hover {
        color: #6995b9;
    }

    @media screen and (max-width: 1920px) {
        .header .menu1 .top1 .logo {
            width: 25%;
        }
    }

    @media screen and (max-width: 1920px) {
        .header .menu1 .top1 .logo {
            width: 25%;
        }

        .header .menu1 .top1 .linemessge {
            width: 75%;
        }
    }

    @media screen and (max-width: 2560px) {
        .header .menu1 .top1 .logo {
            width: 17%;
        }

        .header .menu1 .top1 .linemessge {
            width: 83%;
        }
    }


    .header .menu1 .top1 .links {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 10px 0px;
    }

    .header .menu1 .top1 .links button {
        padding: 5px 10px;
        font-size: 14px;
    }

    .header .menu2 {
        width: 100%;
        height: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        --box-shadow: -3px 6px 6px -3px rgba(28, 28, 33, 0.6);
        background: linear-gradient(180deg, var(--menu2bg1), var(--menu2bg2));
        --border-top: 1px solid #f9c36b;
    }

    .header .menu2 .top2 {
        width: 100%;
        height: 100%;
        padding: 0 336px 0 336px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .header .menu2 .top2 a {
        padding: 10px 10px;
    }

    .header .menu2 .top2 .item {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #fff9ff;
    }
    .header .menu2 .top2 .item:hover {
       transition: .5s;
        font-size: 16px;
        color: #fbcf48;
    }

    .header .menu2 .top2 .dote {
        width: 2px;
        height: 12px;
        background-color: var(--dote);
    }

    .header .mobile_linemessage {
        display: none;
    }

    .header .mobile-menu-left-icon {
        display: none;
    }

    .header .mobile-menu-right-icon {
        display: none;
    }

    .marquee {
        width: 80%;
        line-height: 30px;
        border-radius: 1px;
        box-sizing: border-box;
        padding: 0 0 0 0;
        background-color: #1b1b1b;
        color: #e1e1e1;
        --box-shadow: inset 0 2px 10px rgba(32, 32, 36, 0.92);
    }

    .fly {
        position: absolute;
        top: 0;
        left: 0;
        width: 153px;
        animation: updownmoving 2s ease-in;
        animation-iteration-count: infinite;
    }

    .logoimg2 {
        margin-left: 153px;
    }

    @keyframes updownmoving {
        0% {
            top: 0;
        }
        50% {
            top: -10px;
        }
        100% {
            bottom: 0;
        }
    }
    .cashin{
        width: 49%;
        text-align:center;
        border-radius: 1px;
        background-color: #3b5d77;
        height: 32px;
    }
    .cashout{
        width: 49%;
        text-align:center;
        border-radius:1px;
        background-color: #17b9bb;
        height: 32px;
    }
</style>

