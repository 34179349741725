<template>
    <div class="overlay" :class="{'showNotMemberCustomerPannel':$store.state.isShowNotMemberCustomerePanel && !$store.state.isLogin}">

        <div class="overlay-warp">
            <div class="overlay-open">
                <div class="register-warp">
                    <div class="login-head">
                        <h4>
                            <img src="../assets/images/logo/ptn/logo01.gif" style="height: 50px"/>
                        </h4>
                    </div>
                    <div style="padding: 5px">
                        <div>
                            <textarea v-model="content" style="border: 1px solid #6f6f6f;background-color: #898989;outline: transparent"></textarea>
                        </div>

                        <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
                            <button style="width: 130px;background-color: var(--loginBtnBg);height: 40px;margin-right: 5px;color: #606266" @click="saveNotMemberQuestion">Contact us</button>
                            <button style="width: 80px;background-color: var(--regBtnBg);height: 40px;color: #ff0000" @click="closePanel">Cancel</button>
                        </div>
                    </div>

                    <div v-for="(item,idex) in cusList">
                        <p style="line-height: 38px;border-bottom: 4px solid #767676;padding: 5px"><span v-html="item.content"></span></p>
                        <div v-html="item.contentReply" style="background-color: #606266;padding: 5px">
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../common/mixin";
    import {getnotmemberanswer, saveNotMemberQuestion} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";

    export default {
        name: "NotMemeberCustomerComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                content: '',
                cusList: null,
            }
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.content=''

            },
            saveNotMemberQuestion() { //회원가입시 아이디 체크
                if (this.content == '') {
                    this.$swal({
                        title:'내용을 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                saveNotMemberQuestion({'content': this.content}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$store.state.isShowLoginPanel = false;
                    this.$store.state.isShowRegisterPanel = false;
                    this.$store.state.isShowNotMemberCustomerePanel = false;
                    this.content=''
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '비회원 문의글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

        },
        created() {
            getnotmemberanswer().then(res=>{
                this.cusList = res.data.data
                console.log(this.cusList)
            })
        },
        computed: {

        }
    }
</script>

<style scoped>
    @import url("../assets/css/style.css");
    @media screen and (max-width: 1024px) {
        .register-warp {
            width: 100% !important;
            height: 100vh !important;
        }
        .overlay .overlay-warp .overlay-open{
            width: 100%;
        }
    }

    .showNotMemberCustomerPannel {
        transform: translateY(0%);
    }

    .register-warp {
        width: 750px;
        height: 700px;
        background-color: var(--loginWarpBg);
        color: #fff9ff;
        border:  1px solid #1f1f1f;
        overflow-y: scroll;
    }

    .register-warp .login-head {
        background-color: #000000;

        color: #fff;
        height:100px;
        line-height: 100px;
        padding: 0 5px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
        display: flex;
        justify-content: center;
        align-items: center;
    }



</style>