<template>

        <div class="main">
            <div class="search">
                <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
                <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
                <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">search </el-button>
            </div>
            <div class="data">
                <div style="padding: 5px 0;font-weight: bold;width: 100%">
                   Total Count: {{totalCount}}, Total Amount:{{totalCash |comma}}
                </div>
                <table class="agent_table">
                    <tr>
                        <th>Agent</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                    <tr v-for="item in applyCashList">
                        <td>
                            {{item.agent.nickname}}
                        </td>
                        <td>
                            <i class="fa fa-coins" v-if="item.consiterecharge == agentConst.YES"></i>
                            <span style="display: inline-block;padding: 2px;border-radius: 3px;width: 95%"
                                  :class="{'text-blue':item.amount>=100000}">{{(item.amount * -1)|comma}}</span>
                        </td>
                        <td>
                            {{item.createTime|datef('MM/DD HH:mm')}}
                        </td>
                        <td>
                           <span v-if="item.status == agentConst.ApplyCash.STATUS_WAITING"
                                 class="badge badge-primary">Uncheck</span>
                            <span v-if="item.status == agentConst.ApplyCash.STATUS_CHECKING"
                                  class="badge badge-primary">Checking</span>
                            <span v-if="item.status == agentConst.ApplyCash.STATUS_COMPLITE"
                                  class="text-blue">Success</span>
                            <span v-if="item.status == agentConst.ApplyCash.STATUS_CANCEL" class="text-red">Cancel</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="pagePanel">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[20, 50, 100, 150, 200]"
                        :page-size="20"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>


</template>

<script>
    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import {getApplyAgentExchangeList} from "../../network/agent/commonRequest";
    import {agentMixin} from "../../common/agent/agentMixin";
    import agentConst from "../../common/agent/agentConst";

    export default {
        name: "AgentSubAgentApplyExchange",
        components: {DateSelectorComp, PartnerSelectorComp},
        mixins: [agentMixin],
        data() {
            return {
                applyCash: {user: {}},
                applyCashList: [],
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                selectedPartnerId: null,
                totalCount: 0,
                totalUserCount: 0,
                totalCash: 0,

            }
        },
        methods: {
            search() {
                this.getApplyCashList();
            },
            getApplyCashList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.applyCash.startDate = this.startDate;
                this.applyCash.endDate = this.endDate;
                getApplyAgentExchangeList(this.applyCash, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.total;
                    this.applyCashList = res.data.data.exchangeList;
                    this.totalCash = res.data.data.totalCash;
                    this.totalCount = res.data.data.totalCount;
                    loadingInstance.close();
                })
            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
        },
        created() {
            this.startDate = this.$moment().format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.search()
        },
        watch: {}
    }
</script>

<style scoped>

</style>