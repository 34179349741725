import http from "./axios/http";

export function getJiliGamesMaintaining() {
    return http.get('/jiligames/get_jiligames_maintaining?t=' + new Date().getTime())
}

export function getJiliGamesBalance() {
    return http.get('/jiligames/balance?t=' + new Date().getTime())
}

export function addJiliGamesCash() {
    return http.get('/jiligames/add_jiligames_cash?t=' + new Date().getTime())
}

export function subjectJiligamesCash() {
    return http.get('/jiligames/subject_jiligames_cash?t=' + new Date().getTime())
}

export function getJiliGamesList(search) {
    return http.post('/jiligames/get_jiligames_list?t=' + new Date().getTime(), search)
}

export function getJiliGameLoginUrl(gameid) {
    // return http.get('/jiligames/get_jiligame_login_url?jiligameid' + gameid + '&t=' + new Date().getTime())
    return http.post('/jiligames/get_jiligame_login_url?t=' + new Date().getTime(), {gameid: gameid})
}

export function getJiliBetlist(search, pageNum, pageSize) {
    let url = '/jiligames/jili_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return http.post(url, search);
}
export function deleteAllJiliBet() {
    return http.get('/jiligames/jili_bet_del_all?t=' + new Date().getTime())
}





