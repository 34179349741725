<template>
    <div class="date_selector">
        <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            style="width: 130px"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="Start"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
        </div>
        <div style="padding-left: 1px;padding-right: 1px;color: #cfcfd1">~</div>
        <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            style="width: 130px"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="End"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
        </div>
    </div>
</template>

<script>
    import {manager} from "../../common/administrator/managerMixin";
    import {getPartnerList} from "../../network/manager/partnerRequest";

    export default {
        name: "DateSelectorComp",
        mixins: [manager],
        data() {
            return {
                startDate: '',
                endDate: '',
                pickerOptions: {
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: 'Yesterday',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: 'A week ago',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15 days ago',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30 days ago',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        methods: {
            setStartDate() {
                this.$emit('setStartDate', this.$moment(this.startDate).format('yyyy-MM-DD'))
            },
            setEndDate() {
                this.$emit('setEndDate', this.$moment(this.endDate).format('yyyy-MM-DD'))
            }
        },
        computed: {},
        created() {

        },
        watch: {},
    }
</script>

<style scoped>
    .date_selector {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        margin-left: 5px;
    }
</style>