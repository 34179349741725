<template>
  <div>
    <!--읽지 않은 메세지-->
    <message-comp></message-comp>

    <div class="footer">
      <div class="footer_thirdparty" style="">

        <div class="th_item">
          <img src="../assets/images/foot/world/Asia.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Best.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Dream.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Evo.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Sexy.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Soft.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Evolution.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Haba.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/jili.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/playstar.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Prag.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/relax.png">
        </div>

        <div class="th_item">
          <img src="../assets/images/foot/world/Vivo.png">
        </div>

        <div class="th_item">
          <img src="../assets/images/foot/world/Micro.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Oriental.png">
        </div>
        <div class="th_item">
          <img src="../assets/images/foot/world/Play.png">
        </div>
      </div>

      <div class="copyright">
        <div class="left">
          <img src="../assets/images/foot/world/mega-logo.png">
        </div>
        <div class="right">
          <p>Gaming license provided under the law of Malta - MGA/B2C/543/2023 issued on the 12/01/2023.</p>
          <p>COPYRIGHT © WORLD CASINO ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import MessageComp from "./MessageComp";

export default {
  name: "FootComp",
  components: {MessageComp},
  created() {
  }
}
</script>

<style scoped>
/*.footer {*/
/*    width: 100%;*/
/*    padding: 20px 320px 16px 320px;*/
/*    color: #fff9ff;*/
/*    background: var(--footBg);*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.footer img{*/
/*    width: 100%;*/
/*}*/



.footer {
  width: 100%;
  padding: 0px 320px 16px 320px;
  color: #fff9ff;
  border-top: 1px solid #353535;
  --background: var(--footBg);
}


.footer .footer_cont img {
  max-height: 100px;
}

.footer .footer_cont .footer_bottom .bitem p {
  padding: 3px 0;
  color: #a3a1a7;
}


.footer .footer_thirdparty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.footer .footer_thirdparty .th_item {
  width: 12%;
  text-align: center;
}

.footer .footer_thirdparty .th_item img {
  width: 90%;
}

.footer .copyright{
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}
.footer .copyright .left{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer .copyright .left img{
  width: 80%;
  padding: 0 10px;
}
.footer .copyright .right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

@media screen and (max-width: 1024px) {
  .footer .copyright .left{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .copyright .left img{
    width: 80%;
  }
  .footer .copyright .right{
    width: 100% !important;
  }
  .footer .copyright .right p{
    text-align: center;
    font-size: 10px;
  }
}


</style>