<template>
    <div class="menu-items">

        <router-link tag="div" to="/recharge" class="m-item mrecharge"></router-link>
        <router-link tag="div" to="/exchange" class="m-item mexchange"></router-link>
        <router-link tag="div" to="/customer_center" class="m-item mcustomer"></router-link>
        <router-link tag="div" to="/sports" class="m-item msports"></router-link>
        <router-link tag="div"  :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}" class="m-item mcasino"></router-link>
        <router-link tag="div"  :to="{path: '/casinohonor', query: {type:2,t: new Date().getTime()}}" class="m-item mslot"></router-link>
        <router-link tag="div"  :to="{path: '/jiligames', query: {type:2,t: new Date().getTime()}}" class="m-item mjili"></router-link>
      <router-link tag="div" to="/sports_inplay" class="m-item minplay"></router-link>
        <router-link tag="div" to="/sports_betinfo" class="m-item msbetinfo"></router-link>
        <router-link tag="div" to="/sports_result" class="m-item mgameresult"></router-link>
        <router-link tag="div" to="/notice" class="m-item mnotice"></router-link>
        <router-link tag="div" to="/event" class="m-item mevent"></router-link>
        <router-link tag="div" to="/message" class="m-item mmessage"></router-link>
        <router-link tag="div" to="" class="m-item"></router-link>
        <router-link tag="div" to="" class="m-item"></router-link>
    </div>
</template>

<script>
    export default {
        name: "MobileMenuItemsComp"
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: start;
        flex-wrap: wrap;
        height: 665px;
    }

    .m-item {
        width: 32%;
        height: 130px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        border-radius: 5px;
        --border: 1px solid #2b2b2b;
    }


    .msports {
        background: url(../assets/images/bg/mobile_menu/aqua/sports.png) center center no-repeat;
        background-size: 100%;
    }
    .minplay {
      background: url(../assets/images/bg/mobile_menu/aqua/inplay.png) center center no-repeat;
      background-size: 100%;
    }
    .mgameresult {
        background: url(../assets/images/bg/mobile_menu/aqua/match.png) center center no-repeat;
        background-size: 100%;
    }
    .mcasino {
        background: url(../assets/images/bg/mobile_menu/aqua/casino.png) center center no-repeat;
        background-size: 100%;
    }
    .mslot {
        background: url(../assets/images/bg/mobile_menu/aqua/slot.png) center center no-repeat;
        background-size: 100%;
    }
    .mnotice {
        background: url(../assets/images/bg/mobile_menu/aqua/notice.png) center center no-repeat;
        background-size: 100%;
    }
    .mevent {
        background: url(../assets/images/bg/mobile_menu/aqua/promo.png) center center no-repeat;
        background-size: 100%;
    }
    .mjili {
        background: url(../assets/images/bg/mobile_menu/aqua/jili.png) center center no-repeat;
        background-size: 100%;
    }
    .mcustomer {
        background: url(../assets/images/bg/mobile_menu/aqua/service.png) center center no-repeat;
        background-size: 100%;
    }
    .mrecharge {
        background: url(../assets/images/bg/mobile_menu/aqua/cashin.png) center center no-repeat;
        background-size: 100%;
    }
    .mexchange {
        background: url(../assets/images/bg/mobile_menu/aqua/cashout.png) center center no-repeat;
        background-size: 100%;
    }
    .msbetinfo {
        background: url(../assets/images/bg/mobile_menu/aqua/history.png) center center no-repeat;
        background-size: 100%;
    }
    .mmessage {
        background: url(../assets/images/bg/mobile_menu/aqua/message.png) center center no-repeat;
        background-size: 100%;
    }


</style>